@import url('https://fonts.googleapis.com/css2?family=Cormorant&family=Playfair+Display&family=Raleway&display=swap');


.heading-section-2{
    background-image: url("../assets/bg-page-header.jpg");
    min-width: 100%;
    max-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font-weight: 900;
  }
  .heading-2{
    font-weight: 700;
    font-size: 3rem;
  }
  
  .home-link-2{
    font-weight: 300;
  }
  .home-text-2{
    color: #0039e4 !important;
  }
  

/* Contact Page whole layout */
.contact-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
}

.contact-submain{
    min-width: 100%;
    /* height: 250vh; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
}
.heading-main-banner{
    font-family: 'Raleway',sans-serif;
    font-weight: 800;
}
.main-section{
    width: 80%;
}
.submain-section1{
    display: flex;
    flex-direction: row;
    width: 100%;
    /* align-items: center; */
    justify-content: center;
    margin-top: 2rem;
    height: 45rem;
}

/* Blue Div */
.blue-div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: auto;
    background-image: linear-gradient(145deg, #0039e4 0%, #04dbf1 100%);
}
.blue-div-main{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20%;
    min-width:20vh;
    max-width: 40%;
    /* box-shadow: 18px 40px 0px rgba(2,156,236,0.3); */
}


.blue-div-submain{
    width: 90%;
    height: 95%;
}

.contactus-bulletpoint{
    font-size: 0.8rem;
    margin-top: 0.25rem;
    color: WHITE;
    font-weight: 700;
    letter-spacing: 0.09rem;
}

.title-contact{
    width: 100%;
    font-size: 2rem;
    color: white;
}

.dot-1-p-contact{
    font-size: 0.5rem;
}


/* Form Div */

.form-main-div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 50%;
}
.form-submain{
    display: flex;
    flex-direction: column;
    
}
.text-headers{
    color: #61daff;
    font-weight: 700;
}
.paragraph-div{
    max-width: fit-content;
}



@media screen and (max-width:750px) {
    .main-section{
        width: 92%;
    }
    .submain-section1{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 95%;
        height: fit-content;
    }
    .contact-submain{
        max-width: 100%;
        height: 250vh;
    }
    .blue-div-main{
        max-width: 80%;
        min-width: 80%;
        margin-bottom: 2.5rem;
    }
    .contact-main{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 150vh;
    }
    .heading-section-2{
        /* background-image: url("../assets/bg-page-header.jpg"); */
        min-width: 100%;
        max-height: 50vh;
        min-height: 30vh;
        margin-top: 4rem;
        /* display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-size: contain; */
    }
    .map-main{
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100%;
    }
    
    .map-submain{
        max-width: 95%;
        min-width: 95%;
    }
    


}   

.footer-main-contact{
    margin-top: 0rem;
    min-width: 100%;
    /* border-top: 10px solid black; */
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    max-width: 100%;
    max-height: 200rem;

}
/* 
.footer-text{
    /* color: black; */
    /* text-align: center; 
    justify-content: center;
}  */

.map-main{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    margin: 5rem auto;
}

.map-submain{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    min-width: 98%;
}





