@import url('https://fonts.googleapis.com/css2?family=Cormorant&family=Playfair+Display&family=Raleway&display=swap');

body {
  margin: 0;
  padding: 0;
}
.imp-sub{
margin-left: 5%;
margin-right: 5%;
  
}
.content-container {
  margin-top: 5%;
  padding: 20px;
  background: #ffffff;
}


.heading-section{
  background-image: url("../assets/bg-page-header.jpg");
  min-width: 100%;
  min-height: 40vh;
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-weight: 900;
}
.heading{
  font-weight: 700;
  font-size: 3rem;
}

.home-link{
  font-weight: 300;
}
.home-text{
  color: #0039e4 !important;
}



.image-section {
  max-width: 100%;
  /* padding-left: 300px;
  padding-right: 300px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-size: 26px;
  border-radius: 10px;
  height: 50rem;

}

.custom-image {
  min-width: 35rem; /* Take full width on smaller screens */
  height: 40rem;
  border: none;
  border-radius: 0;
  box-shadow: none; /* Remove box-shadow on smaller screens */
  margin-bottom: 20px; /* Add spacing between image and text on all screens */
  padding-right: 0; /* Remove right padding on smaller screens */
}


.paragraph-imgsec{
  max-width: 50%;
}

@media screen and (max-width:600px) {

  .image-section{
    font-size: medium;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    height: auto;
  }
  .heading-section{
    margin-top: 4rem;
    max-height: 50vh;
    min-height: 30vh;
  }
  
.paragraph-imgsec{
  max-width: 80%;
}
  
  .custom-image{
    min-width: 10rem ;
    max-height: 20rem;
  }
  .imp-sub{
    margin-left: 0%;
    margin-right: 0%;
      
    }

  .content-container{
    max-width: 100%;
  }

  .paragraph-imgsec{
    min-width: 90%;
  }
  .paragraph-additional-text{
    min-width:90%;
  }
  .progress-sub-main{
    margin-bottom:  100%;
    margin-left: 90%;
  }
  .Mission{
    min-width: 100%;
  }
  .digital{
    min-width: 100%;
  }
} 






/* additional text */
.additional-text {
  display: flex;
  align-items: center;
  font-size: 25px;
}

.additional-text-main
{
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  max-width:100%;
  margin-top: 5%;
}
.additional-text-submain
{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  max-width:100%;
  background-color: #f0f6ff;
}
.paragraph-additional-text{
  max-width: 40%;
}
.contactus-bulletpoint{
  color:#bbb6b6
}
/* YourComponent.css */

/* YourComponent.css */

.progress-main {
  max-width: 70%;
  margin-top:5%;
  margin-left: 2%;
}

.progress-sub-main {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.progress-container {
  position: relative;
  margin-right: 10px;
}

.progress-container progress {
  width: 500px; /* Set the desired width */
  height: 10px; /* Set the desired height */
  border-radius: 20px; /* Add rounded corners */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow effect */
}

/* Customize the styles for each progress bar */
.p1, .p2, .p3 {
  width: 500px; /* Set the desired width */
  height: 20px; /* Set the desired height */
  margin-right: 10px; /* Adjust spacing between progress bars */
  border-radius: 20px; /* Add rounded corners */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  position: relative; /* Needed for the ::before pseudo-element */
}

/* Create a curved mask on the left side */
.p1::before, .p2::before, .p3::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px; /* Adjust the negative left value to control the curve */
  width: 20px; /* Adjust the width of the curved mask */
  height: 100%;
  background-color: #00c3ff; /* Match the progress bar color */
  border-radius: 20px 0 0 20px; /* Add curved mask on the left */
}
.p1::after {
  content: '';
  position: absolute;
  top: 0;
  right: 30px; /* Adjust the negative right value to control the curve */
  width: 20px; /* Adjust the width of the curved mask */
  height: 100%;
  background-color: #00c3ff; /* Match the progress bar color */
  border-radius: 0 20px 20px 0; /* Add curved mask on the right */
}
.p3::after{
  content: '';
  position: absolute;
  top: 0;
  right: 110px; /* Adjust the negative right value to control the curve */
  width: 20px; /* Adjust the width of the curved mask */
  height: 100%;
  background-color: #00c3ff; /* Match the progress bar color */
  border-radius: 0 20px 20px 0;
}
.p2::after {
  content: '';
  position: absolute;
  top: 0;
  right: 180px; /* Adjust the negative right value to control the curve */
  width: 20px; /* Adjust the width of the curved mask */
  height: 100%;
  background-color: #00c3ff; /* Match the progress bar color */
  border-radius: 0 20px 20px 0;
}
/* Customize the color of each progress bar */
.p1::-webkit-progress-value,
.p2::-webkit-progress-value,
.p3::-webkit-progress-value {
  background-color: #00c3ff; 

}

/* Customize the color of the remaining part of each progress bar */
.p1::-webkit-progress-bar,
.p2::-webkit-progress-bar,
.p3::-webkit-progress-bar {
  background-color: #ffffff; /* Light gray color */
  margin-right: 10px; /* Adjust spacing between progress bars */
  border-radius: 20px; /* Add rounded corners */
  box-shadow: 5px 5px 5px rgba(0, 130, 252, 0.2);
}

/* Styles for the overlay value */
.progress-value {
  position: absolute;
  top: 0;
  right: 55%;
  bottom: 90%;
  padding: 5px;
  border-radius: 0 20px 0 20px; /* Adjust border-radius for appearance */}



.next_image {
  min-width: 50%; /* Take full width on smaller screens */
  padding-right: 0; /* Remove right padding on smaller screens */
  height: auto;
  border: none;
  border-radius: 0;
  box-shadow: none; /* Remove box-shadow on smaller screens */
}


.orange-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  width: 85%; /* Set the desired width */
  height: 15rem;
  border: 1px solid #ddd; /* Add border for card */
  border-radius: 20px; /* Add rounded corners */
  overflow: hidden; /* Hide overflow content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow effect */
  background-image: url('../assets/bg-counter-1.jpg');
  font-family: "Red Hat Display", sans-serif;
  font-weight: 900;
  color: #1a1b1e;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2%;
  /* height: 300px; */
  box-shadow: 0 0 30px rgba(255, 145, 0, 0.5);

}

.card-body {
  display: flex;
  width: 85%;
  justify-content: space-evenly;
  font-size: small;
  color: #facb89;
  text-shadow: #ffffff;
}

.card-body p{
  font-size: 3rem;
  color: #ffffff;
}

/* Styles for each part of the card */
.card-part1,
.card-part2,
.card-part3,
.card-part4 {
  padding: 30px;
   /* Add a border between parts */
}

/* Style the last part to remove the border */
.card-part4 {
  border-bottom: none;
}
/* Additional text Mobile */
@media screen and (max-width:600px) {
.pioneering-text{
  line-height: 2.25rem;
}

  .additional-text
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0%;
    height: 100%;
    max-width:100%;
    overflow-x: hidden;
  }
  .additional-text-submain
{
  max-width:90%;
}
.progress-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width:100%;
}
.progress-sub-main{
  width:30rem;
  margin-left: 2%;
}
.progress-container{
  margin-top: 3%;
}
.progress-container progress{
  max-width: 70%;
}
.progress-value{
  right: 30%;
  bottom: 90%;
}
.orange-card{
  width: 100%;
  display: flex;
  margin-left: 0;
  min-height: 400px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.card-body{
  width: 100%;
  display: flex;
  margin-right: 20%;
  justify-content: flex-start;
  align-items: center;
}
}

@media screen and (min-width: 1200px){
  .orange-card{
    width:auto;
    display: flex;
  }
  .progress-value{
    right:75%;
  }
}

@media screen and (max-width:900px) {
  .additional-text
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
    height: 100%;
    max-width:100%;
  }
  .additional-text-submain
{
  max-width:100%;
}
.progress-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30%;
    margin-top: 20%;
}
.progress-container{
  margin-top: 3%;
}
.progress-container progress{
  max-width: 70%;
}
.progress-value{
  right: 30%;
  bottom: 90%;
}
.orange-card{
  max-width: 100%;
  display: flex;
  min-height: 600px;
  flex-direction: row;
  justify-content:center;
  align-items: center;
}
.card-body{
  display: inline;
  align-items: center;
  margin-left: 25%;
}
}

.next_image:hover {
  transform: none; /* Remove transform on smaller screens */
  transition: none; /* Remove transition on smaller screens */
  box-shadow: none; /* Remove box-shadow on smaller screens */
}

.card-container {
  background: #f0f6ff;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 20px; /* Adjust padding on smaller screens */
  gap: 20px; /* Adjust gap on smaller screens */
}

.card1,
.card2,
.card3 {
  flex: 0 0 calc(100% - 20px); /* Take full width on smaller screens */
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 20px;
  height: auto; /* Auto height on smaller screens */
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  text-align: justify;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add ease transition */
}

.card1{
  background-image: url('../assets/bg-service1-1.png');
  background-position: top right;
  background-repeat: no-repeat;
}
.number-box {
  float: left;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #00c3ff;
  font-family: "Red Hat Display", sans-serif;
  font-weight: bold;
  font-size: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background: rgba(0, 195, 255, 0.15);
  margin-right: 5px;
}
.service-box-title {
  font-weight: 700;
  margin-bottom: 8px;
}
.card2{
  background-image: url('../assets/bg-service2-1.png');
  background-position: bottom right;
  background-repeat: no-repeat;
}
.number-box-2{
    float: left;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #1c45d8;
    font-family: "Red Hat Display", sans-serif;
    font-weight: bold;
    font-size: 20px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    background: rgba(97, 47, 236, 0.15);
    margin-right: 5px;
}

.card3{
  background-image: url('../assets/bg-service3-1.png');
  background-position: top right;
  background-repeat: no-repeat;
}
.number-box-3{
  float: left;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #ed3611;
  font-family: "Red Hat Display", sans-serif;
  font-weight: bold;
  font-size: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background: rgba(232, 131, 80, 0.15);
  margin-right: 5px;
}

.card1:hover,
.card2:hover,
.card3:hover {
  transform: translateY(-20px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add ease transition */
}

/* Media queries for responsiveness */
@media screen and (max-width: 600px)
{
  .paragraph-additional-text{
    max-width: 80%;
}
.additional-text {
  font-size: 20px; /* Adjust font-size on larger screens */
  justify-content: space-around;
}
}
@media screen and (min-width: 600px) {
  
  .custom-image,
  .next_image {
      max-width: 30%; /* Adjust max-width on larger screens */
      padding-right: 60px; /* Restore right padding on larger screens */
      box-shadow: #000; /* Restore box-shadow on larger screens */
  }

  .additional-text {
      font-size: 20px; /* Adjust font-size on larger screens */
      justify-content: space-around;
  }

  .card1,
  .card2,
  .card3 {
      flex: 0 0 calc(33.33% - 20px);
  }
}

@media screen and (min-width: 1200px) {
  .social-media-container {
      max-width: 100%;
      padding-right: 0; /* Remove right padding on larger screens */
      text-align: left; /* Align text to the left on larger screens */
  }
  
}

@media screen and (max-width:1200px)
{
  .additional-text-main{
   
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    font-size: small;
  }
  .additional-text-submain{
    display: flex;
    flex-direction: column;
    flex-direction: row;
  }
  .paragraph-additional-text{
    display: flex;
    flex-direction: column;
    font-size: medium;
  }
  .additional-text p{
    display: flex;
    /* font-size:10px; */
  }
  .next_image{
    display: flex;
    padding: 0;
  }
  .progress-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 30%;
    max-width:10%;
}
.progress-container{
  margin-top: 3%;
}
.progress-container progress{
  max-width: 70%;
}
.progress-value{
  right: 30%;
  bottom: 90%;
}
}


/* Media queries for responsiveness */




/* Add styles for the CircularProgressBar component if needed */


.social-media-container {
  background: #ffffff;
  height: auto; /* Adjusted to auto height for responsiveness */
  padding: 20px;
  font-size: larger;
  text-align: justify;
}

.last-section {
  display: flex;
  width: 200%;
  min-height: 700px;
  justify-content: flex-start;
  justify-content:space-around;
  padding: 10;
  background: #0042a1;
  position: relative;
  font-size: large;
  font-size: 25px;
  color: #fff;
  font-weight: 700;
}
.social-media-about{
  min-width: 10%;
  
}
/* 
.about-moit{
  max-width:10%;
  margin-left: 50px;
  color:#fff;
  
}
.moit-img{
  margin-bottom: 20%;
} */

.social-media li a{
  text-align: left;
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s ease;
}

.social-media ul li a:hover {
  color: #0095ff;
}

.address {
  padding-right: 20px;
  padding-left: 10px;
}

.social-media {
  padding-left: 20px;
}

/* Media queries for responsiveness */

@media screen and (max-width: 768px) {
  .social-media-container {
      padding-right: 20px; /* Adjusted right padding for smaller screens */
  }
  .paragraph-additional-text{
    font-size: small;
  }
  .image-section{
    display: flex;
    flex-direction: column-reverse;
    justify-content:flex-end;
    max-width: 100%;
  }
  .image-section-main{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .last-section{
    width: 100%;
    min-height: 200px;
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
    align-items:flex-start;
    font-size: x-small;
  }
  .about-moit{
    max-width: 30%;
    margin-left: 2%;
  }
  .moit-para{
    justify-content: flex-start;
  }
  .social-media-about{
    padding-left: 30px;
  }
  .social-media li{
    text-decoration: none;
    font-style: none;
  }
  .contact-info{
    padding-left: 70px;
  }
  .address{
    padding-left: 10px;
    padding-right: 80px;
  }
}

@media screen and (min-width: 900px) {
  .additional-text{
    padding: 0%;
  }
  .last-section {
      flex-direction: column; /* Change flex-direction to column on larger screens */
      align-items: center; /* Center items on larger screens */
  }

  .last-section{
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size:large;
  }

}
.last-section{
  background-image: url('../assets/bg-shape3-home6.png');
}

@media screen and (min-width:900px){
  .progress-main{
    max-width: 100%;
    align-items: center;
  }
  .progress-sub-main{
    align-items: center;
  }
  .progress-value{
    position: absolute;
    top: 0;
    right: 5%;
  }
  .social-media-container{
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .Mission {
    max-width: 85%;
  }
  .digital{
    max-width: 95%;
  }
  .mission-para{
    /* font-family: 'Comorant',sans-serif; */
    font-size: 10px;
    color: #606060;
    line-height: 1;
  }
}

.social-media-container{
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Mission {
  max-width: 80%;
}
.digital{
  max-width: 80%;
}
.mission-para{
  /* font-family: 'Comorant',sans-serif; */
  font-size: 16px;
  color: #606060;
  line-height: 1.875;
}