.footer3{
  min-width: 100%;
  /* background: #0042a1; */
  /* background-image: url('../assets/bg-shape3-home6.png'); */
  display: flex;
  flex-direction: column;
}
.footer3-main{
  min-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #0042a1;
  flex-wrap: wrap;
}
.footer-shape{
  background-color: #0042a1;
}
.footer-sub-main{
  background-image: url('../assets/bg-shape2-home6.jpg');
  background-repeat: no-repeat;
  min-width:100%;
  min-height: 100%;
  background: #0042a1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: white;
  font-size: larger;
}
.footer3-bold{
  font-weight: 900;
  color: aqua;
}
.Copyright{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
  margin-bottom: 4%;
  font-size: large;
  /* background-color: #0042a1; */
  color: #fff;
}
.about-moit{
  margin-top: 8%;
  max-width:15%;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color:#fff;
}
.social-media-about-2{
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.contact-info-2{
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.address-2{
    margin-top: 10%;
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

@media screen and (max-width: 600px){
  
  .footer-sub-main{
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    justify-content: space-between;
    align-items:center;
    font-size: x-small;
  }
  .about-moit{
    max-width: 30%;
    margin-left: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .moit-para{
    justify-content: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .social-media-about-2{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .social-media-about-2 li{
    text-decoration: none;
    font-style: none;
  }
  .contact-info-2{
    /* padding-left: 70px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .address-2{
    /* padding-left: 15px; */
    /* padding-right: 80px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Copyright{
      margin-top: 5%;
      margin-left: 5%;
      align-items: center;
      font-size: smaller;
  }

}

.list-items-footer3{
  gap: 2rem;
}

.footer3-a{
  margin-left: 1rem;
}