@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;700&family=Playfair+Display:wght@400;800&family=Raleway:wght@400;800&family=Red+Hat+Text:wght@400;500;600;700&display=swap');





/* SECTION 1 */
.main-div{
  /* background-image: url('../assets/bg-shape3-home6.png'); */
  /* background-position: center; */
  background-color: #0049b1;
  display: flex;
  flex-direction: column;
  height: auto;
  /* overflow-y: hidden; */
}


.section1-main2-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-evenly; */
  height: 110vh;
  background-image: url('../assets/bg-banner-home6.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #1459BA;
}

.section1-main3-div{
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 2.5rem;
}
.typewriter{
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  font-size: 3rem;
  text-align: left;
  min-height: 10rem;
  max-height: 15rem;
  
}

.text-section1{
  max-width: 35%;
  display: flex;
  flex-direction: column;
  margin-top: 15vh;
  margin-left: 2rem;
  /* align-items: flex-start ; */
  /* justify-content: center; */
}
.it-home-pic{
  width: 85%;
  height: 25rem;
  margin-right: 2rem;
}
.img-div{
  display: flex;
  justify-content: flex-end;
  max-width: 55%;
  margin-top: 15vh;
}

.p-section1-part1{
  color: #0EC6FF;
  text-align: left;
  font-size: large;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;
  /* margin-top: 5rem; */
}
.p-section1-part2{
  color: #0EC6FF;
  font-size: 1.45rem;
  color: #0EC6FF;
  text-align: left;
  font-size: large;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;
}

.unlocking-text-div{
  display: flex;
  align-items: flex-start;
}

.unlocking-text{
  color: white;
  font-size: 4rem;
  align-self: flex-start;
  font-weight: bolder;
  justify-content: flex-start;
  text-align: left;
  line-height: 4.5rem;
}

.contactus-home-btn{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  background-color: #fe4c1c;
  width: 19vh;
  height: 2rem;
  align-items: center;
  border-radius: 7rem 7rem 7rem 7rem;
  color: white;
  font-weight: bolder;
  margin-top: 1rem;
}
.contactus-home-btn-p{
  margin-top: 0.4rem;
}


/* SECTION 1 - MOBILE */

@media screen and (max-width: 1020px){
  
  .section1-main2-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* justify-content: space-evenly; */
    height: 110vh;
    background-image: url('../assets/bg-banner-home6.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .section1-main3-div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .typewriter{
    color: white;
    font-family: 'Red Hat Text', sans-serif;
    font-weight: 800;
    font-size: 22px;
    text-align: center;
    height: 6rem;
  }
  .p-section1-part1{
    text-align: center;
  }
  .p-section1-part2{
    text-align: center;
  }
  .it-home-pic{
    width: 100%;
    height: 18rem;
    /* margin-right: 2rem; */
  }
  .unlocking-text{
    color: white;
    font-size: 2rem;
    align-self: center;
    font-weight: bolder;
    justify-content: center;
    text-align: center;
    line-height: 3rem;
  }
  
  .img-div{
    min-width: 90%;
    align-items: center;
    margin-top: 15vh;
    display: flex;
    justify-content: center;
  }
  .text-section1{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15vh;
    margin-left: 0rem;
    
    align-items: center;
    justify-content: center;
  }
  
  .section1-main2-div{
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    height: auto;
    background-image: url('../assets/bg-banner-home6.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

}

/* Background2 */

.background2{
  background-image: url("../assets/bg-shape1-home6.png");
  /* height: 500vh; */
  background-repeat: no-repeat; 
  /* z-index: 10; */
  background-color: #0049B1;
}

/* SECTION 2 */

.section2-main-div{
  /* height: 200vh; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  max-width: 100%;
}

.small-tittle-div{
  margin-top: 15vh;
  display: flex;
  flex-direction: row;
}

ul {
  list-style-type: disc; /* or disc, circle, etc. */
  color: #0EC6FF;  
}

.small-title-p{
  color: #0EC6FF;  
  font-weight: 800;
}

/* li::after{
  position: absolute;
  width: 7px;
  height: 7px;
  background: #0EC6FF;
  border-radius: 100%;
}

li::before{
  position: absolute;
  width: 7px;
  height: 7px;
  background: #0EC6FF;
  border-radius: 100%;
} */

.it-solutions{
  font-family:  'Raleway',sans-serif;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.card-div{
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
} 

.card-div-row{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 5rem;
  width: 100%;
}

.card{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: 40vh;
  justify-content: space-evenly;
  border-radius: 1rem;
}

.card-headers{
  color: white;
  font-size: 1rem;
  font-weight: bolder;
}

.card-paragraphs{
  width: 75%;
  font-size: 0.8rem;
  color: aqua;
  font-weight: 400;
  text-align: center;
}

.section2-card-icon{
  color: #0EC6FF;
  font-size: 35px;
}

.card:hover{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 0 10px rgba(138, 138, 138, 0.2);
  transform: scale(1.1);
  transition: box-shadow 0.3s ease, transform 0.5s ease; 
}

/* Section2 mobile */
@media screen and (max-width: 1020px){
  
  .card-div-row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
  }
  .it-solutions{
    font-size: 22px;
    max-width: 90%;
  }
  .card-div{
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: center;
  }
  .card{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    height: 30vh;
    justify-content: space-evenly;
    border-radius: 1rem;
    margin-top: 2rem;
  }
  .small-tittle-div{
    margin-top: 20vh;
    display: flex;
    flex-direction: row;
  }
}




/* Section 3 */

.section2-2-main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding-bottom: 5rem;
  max-width: 100%;
}
.section2-2-submain{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5rem;
  max-width: 80%;
  justify-content: center;
}

.section2-2-content-div{
  max-width: 50%;
  line-height: 1.5rem;
}
.section2-2-image-div{
  width: 40%;
  margin-left: 2.5rem;
}


.section2-2-small-title-main-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.dot-1{
  /* position: absolute; */
  width: 7px;
  height: 7px;
  /* margin-left: 20px; */
  background: #0EC6FF;
  border-radius: 100%;
  font-size: 5px;
  margin-top: 3px;
  align-items: center;
  justify-content: center;
  display: flex;

}
.dot-1-p{
  margin-top: 0;
  height: auto;
  color: #0EC6FF;
}

.section2-2-small-title-div{
  width: fit-content;
  margin-left: 10px;
}

.section2-2-small-title{
  font-weight: 700;
  color: #0EC6FF;
  text-align: left;
  font-size: 1.5rem;
  font-family: 'Raleway',sans-serif;
}

.section2-2-title-div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.section2-2-title{
  font-family: 'Raleway',sans-serif;
  text-align: start;
  color: white;
  font-weight: 800;
  font-size: 2rem;
  line-height: 30px;
  margin-top: 1rem;
}

.section2-2-para1{
  font-family: 'Red Hat Display',sans-serif;
  font-size: 1.5rem;
  color: #EEEEEE;
  text-align: start;
  line-height: 35px;
}
.section2-2-para2{
  font-family: 'Red Hat Display',sans-serif;
  font-size: 1.178rem;
  line-height: 32px;
  text-align: left;
  color: #EEEEEE;
  font-weight: 300;
}
.section2-2-para-div{
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 25rem;
}
/* .section2-2-image{
  width: 50rem;
  height: 50rem;
} */

.section2-2-submain2{
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 35vh;
  margin-top: 1rem;
  margin-bottom: 10vh;
  /* flex-wrap: wrap; */
}

.progress{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
}
.progress1{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
.progress-subheaders{
  text-align: center;
  font-size: 1.4rem;
  color: white;
  font-weight: 500;

}

.progressbar2{
  margin-top: 17rem;
}

.outer-path{
  border-radius: 100%;
  border: 15px solid #003F99;
  display: flex;
  justify-content: center;
  align-items: center;
}



/* SECTION2-2 mobile */

@media screen and (max-width: 1020px) {
  .section2-2-submain{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  
  .section2-2-content-div{
    max-width: 90%;
  }
  .section2-2-image-div{
    width: 90%;
    margin-left: 0;
    margin-top: 2rem;
  }   
  .section2-2-small-title-main-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .section2-2-title-div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .section2-2-title{
    text-align: left;
    color: white;
    font-weight: 800;
    font-size: 22px;
    margin-top: 1rem;
  }

  .section2-2-para1{
    font-size: 1rem;
    color: white;
    text-align: left;
  }
  .section2-2-para2{
    font-size: 0.75rem;
    color: white;
    text-align: left;
  }
  
  .section2-2-image{
    width: 20rem;
    height: 20rem;
  }

  .section2-2-image-div{
    display: flex;
    justify-content: center;
  }

  .section2-2-submain2{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .progress{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    margin-top: 2rem;
  }
  .progress1{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 2rem;
  }
  .progress-subheaders{
    text-align: center;
    font-size: 1.4rem;
    color: white;
    font-weight: 500;
  
  }
  
  .progressbar2{
    margin-top: 2rem;
  }




}


/* SECTION 3 */

.section3{
  background-image: url(../assets/bg-shape2-home6.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 200%;
  /* margin-top: 20vh; */
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  align-items: center;
  background-color: #1459ba;
}

.section3-main{
  background-image: url("../assets/bg2-dots-home6.png");
  background-repeat: no-repeat;
  /* background-position:top left; */
  background-color: transparent;
  height: auto;  
  max-width: 100%;
  background-color: #1459ba;
  display: flex;
  justify-content: center;
  /* padding-top: 20vh; */
  /* padding-bottom: 20vh; */
}


.section2-2-small-title-main-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.dot-1-2{
  /* position: absolute; */
  width: 7px;
  height: 7px;
  /* margin-left: 20px; */
  background: #0EC6FF;
  border-radius: 100%;
  font-size: 5px;
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;

}
.dot-1-p-2{
  color: #0EC6FF;
}

.section3-small-title-div{
  width: fit-content;
  margin-left: 10px;
}

.section3-small-title{
  font-weight: 700;
  color: #0EC6FF;
  text-align: left;
  font-size: 1.5rem;
  font-family: 'Raleway',sans-serif;
  margin-top: 1rem;
}

.section3-title-div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.section3-title{
  font-family: 'Raleway',sans-serif;
  text-align: start;
  color: white;
  font-weight: 800;
  font-size: 2rem;
  line-height: 30px;
  margin-top: 1rem;
}



/* 
.section3-submain{
  width: 90%;
} */

.section3-headers{
  max-width: 100%;
  margin-left: 9rem;
  /* margin-top: 2rem; */
}

.tabs{
  margin-top: 2.5rem;
}




@media screen and (max-width:1020px) {
  .section3{
    background-image: url(../assets/bg-shape2-home6.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    /* margin-top: 20vh; */
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    align-items: center;
    background-color: #1459ba;
    overflow-x: hidden;
  }
  .section3-headers{
    /* max-width: 100vh; */
    max-width: 80%;
    margin-left: 2.5rem;
  }
  

  .section3-main{
    background-image: url("../assets/bg2-dots-home6.png");
    background-repeat: no-repeat;
    /* background-position:top left; */
    background-color: transparent;
    height: auto;  
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5vh;
  }
  
  .section3-submain{
    max-width: 100%;
    overflow-x: hidden;
  }
  .tabs{
    margin-top: 2.5rem;
  }
  .section3-small-title-div{
    width: fit-content;
    margin-left: 10px;
  }
  
  .section3-small-title{
    font-weight: 700;
    color: #0EC6FF;
    text-align: left;
    font-size: 1.5rem;
    margin-top: 0.5rem;
  }
  
  .section3-title-div{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .section3-title{
    font-family: 'Raleway',sans-serif;
    text-align: start;
    color: white;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
  }
  
}



.section4-main{
  width: 100%;
  /* background-color: #0049b1;  */
  display: flex;  
  justify-content: center;
  align-items: center;
  /* margin-top: 10vh; */
  height: fit-content;
  /* border: 2px solid red; */
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.dot-1-3{
  /* position: absolute; */
  width: 7px;
  height: 7px;
  /* margin-left: 20px; */
  background: #0EC6FF;
  border-radius: 100%;
  font-size: 5px;
  margin-top: 18px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.dot-1-p-3{
  margin-top: 0;
  height: auto;
  color: #0EC6FF;
}


.section4-bgoverlay{
  background-image: url("../assets/bg-shape4-home6.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 10rem;
  margin-bottom: 10rem; */
  height: max-content;
}

.section4-submain{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
}

.section4-image-div{
  max-width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.section4-content-div{
  max-width: 45%;
}

.section4-para{
  margin-top: 1rem;
  font-family: 'Red Hat Text',sans-serif;
  font-size: 1rem;
  line-height: 32px;
  text-align: left;
  color: white;
  font-weight: 300;
}


@media screen and (max-width: 1020px){
  .section4-submain{
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

  }
  .section4-image-div{
    max-width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .section4-content-div{
    max-width: 80%;
  }
  .section4-para{
    margin-top: 1rem;
    font-size: 15px;
    text-align: left;
    color: white;
    font-weight: 300;
  }
}
