/* onclickform.css */

.contact-form-container {
    max-width: 700px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -400px;
    background-color: #ffffff; /* Updated background color */
    overflow-y: auto;
    transition: right 0.3s ease;
    max-height: 60%;
    padding-top: 50px;
  }
  .contact-form-overlay {
    position: fixed;
    top: 20;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 99;
    max-height: 60%;
  }
  .top{
    box-sizing: border-box;
  }
  .contact-form-overlay.open {
    display: block;
    padding-top: 30px;
    background-color: #ffffff;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    
  }
  .form-group{
    border: #000000;
    border-width: 20px;
  }
  .my-form{
    border: #000000;
    border-width: 10px;
  }
  .title {
    text-align: center;
    color: #000000; /* Title text color */
    font-weight: 900;
    margin-bottom: 20px; /* Adjusted margin */
  }
  
  .form-group {
    margin-bottom: 30px; /* Adjusted margin */
  }
  
  .form-group label {
    font-size: 20px; /* Increased label font size */
    color: #000000; /* Label text color */
    margin-bottom: 10px; /* Adjusted margin */
    margin-right: 15px;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 8px; /* Adjusted padding */
    font-size: 16px; /* Increased input font size */
    color: #333333; /* Input text color */
    border: 2px solid #00ff2a; /* Border color */
    border-radius: 8px; /* Increased border radius */
  }
  
  .form-group textarea {
    resize: horizontal; /* Disable horizontal resizing */
  }
  
  .submit-button {
    background-color: #7ef091;
    color: #000000;
    padding: 12px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 18px; /* Increased font size */
    transition: background-color 0.3s ease; /* Smooth transition */
  }
  
  .submit-button:hover {
    background-color: #5cbf70; /* Darker color on hover */
  }
  
  .form-icon {
    position: fixed;
    bottom: 45px;
    right: 20px;
    cursor: pointer;
    z-index: 999;
  }
  
  .form-icon svg {
    font-size: 60px;
    color: #ffffff; /* Icon color */
  }
  
  /* Add styles for the form when it is open */
  .contact-form-container.open {
    right:0;
    max-height: 80%;
    padding-top: 50px;
    padding-left: 20px;
    width: 400px;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a box shadow with a slight transparency */
  }
  .contact-form-main{
    width: 100%;
    font-size: x-large;
  }

  @media screen and (max-width:600px){
    .contact-form-container.open{
        margin-top: 40%;
    }
    .contact-form-container{
      display: none
      ;
    }
    .contact-sub-main{
      display: flex;
      flex-direction: flex-end;
    }
    .form-icon{
      position: fixed;
      right:40;
  }
  }

  
